<template>
  <div id="login">
    <div class="img_logo">
      <img src="../../../assets/images/logo.png"/>
    </div>
    <div class="title-1">
      2022年清华大学特殊类型招生考试
    </div>
    <div class="title-2">
      准考证下载
    </div>
    <el-form
      :model="loginForm"
      :rules="loginRules"
      ref="loginFormRef"
      label-width="130px"
    >
      <el-form-item label="账号：" prop="zkz_num">
        <el-input v-model="loginForm.zkz_num"></el-input>
      </el-form-item>
      <el-form-item label="密码：" prop="id_card">
        <el-input v-model="loginForm.id_card" :type="flag ? 'text' : 'id_card'">
          <!-- <i slot="suffix" class="el-icon-view" :style="{margin: '12px 25px 0 0',fontSize: '18px', color: flag ? '#5c307d' : '#dcdfe6'}" autocomplete="auto" @click="flag = !flag" /> -->
        </el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submitForm()">登 录</el-button>
        <!--<div class="info_bt">请考生于2月22日起登录查询并下载打印准考证</div>-->
      </el-form-item>
    </el-form>
    <dialog-box
      :dialogVisibleFu="dialogVisibleFu"
      :dialogMessageFu="dialogMessageFu"
      @closeDialog="closeDialog"
    ></dialog-box>
  </div>
</template>
<script>
  import dialogBox from "c/index/DialogBox";
  import {login} from "r/index/login.js";
  import validator from "common/validator";

  export default {
    name: "login",
    data() {
      return {
        dialogVisibleFu: false,
        dialogMessageFu: "",
        loginForm: {
          zkz_num: "",
          id_card: ""
        },
        loginRules: {
          id_card: [
            {required: true, message: "密码不能为空", trigger: "blur"},
            // {validator: validator.validateIdCard, trigger: "blur"},
          ],
          zkz_num: [
            {required: true, message: "账号不能为空", trigger: "blur"}
          ],
        },
        flag: false
      };
    },
    methods: {
      closeDialog(value) {
        this.dialogVisibleFu = value;
      },
      submitForm() {
        // if (this.loginForm.id_card == "") {
        //   this.dialogMessageFu = "身份证号不能为空！";
        //   this.dialogVisibleFu = true;
        //   return;
        // }
        // if (this.loginForm.zkz_num == "") {
        //   this.dialogMessageFu = "密码不能为空！";
        //   this.dialogVisibleFu = true;
        //   return;
        // }
        this.$refs['loginFormRef'].validate(async (valid) => {
          if (!valid) return;
          // 发送请求的操作
          let {data: res} = await login(this.loginForm);
          console.log(res);
          // 根据返回的状态码做想关的判断
          if (res.code !== 200) {
            this.dialogMessageFu = res.message;
            console.log(res.message, "反馈信息")
            return (this.dialogVisibleFu = true);
          }
          // token存储
          window.localStorage.setItem("index-token", res.token);
          window.localStorage.setItem("username", res.name);

          // 重置表单
          this.$refs.loginFormRef.resetFields();

          // 使用编程式导航实现跳转
          await this.$router.push({name: "AdminTCK"});
        });
      }
    },
    components: {
      dialogBox
    }
  };
</script>

<style scoped lang="scss">
  #login {
    box-sizing: border-box;
    width: 580px;
    margin: 0 auto;
    // height: 560px;
    background-color: #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 1rem 3rem 3rem 3rem;

    .img_logo {
      margin: 30px 0 10px;

      img {
        width: 191px;
        height: 60px;
      }
    }

    .title-1 {
      font-size: 28px;
      color: #5c307d;
      text-align: center;
      margin-top: 25px;
    }
    .title-2 {
      font-size: 28px;
      color: #5c307d;
      text-align: center;
      margin-bottom: 20px;
    }

    ::v-deep .el-form {
      padding-top: 15px;

      .el-form-item {
        position: relative;

        .el-form-item__label::before{
          content: "";
        }
        .el-form-item__content {
          position: relative;
          text-align: left;

          .label {
            width: 100px;
            height: 40px;
            line-height: 40px;
            text-align: right;
            font-size: 15px;
            position: absolute;
            left: -130px;
            padding: 0 15px;
          }
          .el-input {
            // width: 316px;
            width: 316px;
            height: 40px;
            /*padding: 0 15px;*/
            .el-input__inner {
              width: 316px;
              border-radius: 0;
              color: #333333;
              border-color: #dcdfe6;

              &:hover {
                border-color: #5c307d;
              }
            }
          }

          .el-form-item__error {
            /*color: #ffffff;*/
          }
        }

        .el-button {
          width: 316px;
          border-radius: 0;
          border-color: #5c307d;
          background-color: #5c307d;
          color: #ffffff;
          /*margin-left: 15px;*/
          font-size: 16px;
          font-family: "微软雅黑";
        }
      }
    }

    .info_bt {
      color: #f59a23;
      text-align: left;
      padding: 0 0 10px 15px;
      font-size: 12px;
    }
  }
</style>
