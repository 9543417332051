<template>
  <el-dialog
    title=""
    :visible.sync="dialogVisible"
    top="120px"
    :modal="false"
    :show-close="false"
    @open="openDialog"
  >
    <div class="info">
      <i class="el-icon-circle-close"></i> {{ dialogMessageFu }}
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: "DialogBox",
  props: {
    dialogVisibleFu: Boolean,
    dialogMessageFu: String
  },
  data() {
    return {
      dialogVisible: this.dialogVisibleFu
    };
  },
  methods: {
    // 取消,关闭弹框，修改父组件的值
    cancelBtn() {
      this.dialogVisible = false;
      this.$emit("closeDialog", this.dialogVisible);
    },
    openDialog() {
      var timer;
      timer = setTimeout(() => {
        this.cancelBtn();
      }, 2000);
    }
  },
  watch: {
    dialogVisibleFu() {
      this.dialogVisible = this.dialogVisibleFu;
    }
  }
};
</script>

<style scoped lang="scss">
::v-deep .el-dialog {
  position: absolute;
  left: 170px;
  top: 100px;
  box-shadow: none;
  border: 1px solid #999999;
  .el-dialog__header {
    padding: 0;
  }

  .el-dialog__body {
    padding: 15px;
    .info {
      text-align: left;
      font-size: 16px;
      font-family: "微软雅黑";
      color: #666666;
      line-height: 35px;
      .el-icon-circle-close {
        font-size: 35px !important;
        color: rgb(241, 152, 110) !important;
        vertical-align: top;
      }
    }
  }
}
</style>
